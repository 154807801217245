<template>
  <aside class="page__sidebar">
    <div class="sidebar_balance">
      <div class="card card_first">
        <span>Saldo caixa</span>
        <h2 v-if="!loading">{{ cashBalance }}</h2>
        <loading class="loading" v-else />
      </div>
      <div class=" card">
        <span>Saldo coletores</span>
        <h2 v-if="!loading">{{ balanceCollectors }}</h2>
        <loading class="loading" v-else />
      </div>
    </div>

    <div class="card_total">
      <span>Saldo Total</span>
      <h1 v-if="!loading">{{ totalBalance }}</h1>
      <loading class="loading" color="#ffffff" v-else />
    </div>

    <div class="sidebar__nav">
      <router-link
        class="sidebar__nav--anchor"
        :class="{ current: current == 1 }"
        to="/extratos"
        title="Extratos"
      >
        <i class="icon icon-extract"></i>
        Extratos
      </router-link>

      <router-link
        class="sidebar__nav--anchor"
        :class="{ current: current == 2 }"
        to="/transferencias"
        title="Transferências"
      >
        <i class="icon icon-transfers"></i>
        Coletores
      </router-link>
    </div>
  </aside>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  components: { Loading: () => import('./loading-small.vue') },
  props: {
    current: Number
  },
  data: () => ({
    loading: true
  }),
  methods: {
    ...mapActions('monetary', ['getData'])
  },
  computed: {
    ...mapGetters('monetary', [
      'totalBalance',
      'cashBalance',
      'balanceCollectors'
    ])
  },
  async created () {
    this.loading = true
    await this.getData()
    this.loading = false
  }
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 28px;
}

h2 {
  font-size: 16px;
}

.loading,
h2,
h1 {
  margin-top: 10px;
}
</style>
